import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'produto-item-valor',
  templateUrl: './produto-item-valor.component.html',
  styleUrls: ['./produto-item-valor.component.scss']
})
export class ProdutoItemValorComponent implements OnInit {

  @Input("valor") valor             = 0; 
  @Input("classCustom") classCustom = "";
  @Input("template") template       = "default";
  @Input("servico") servico         = null;
  public valor1 = 0;
  public valor2 = 0;
  
  constructor(){} 

  /**
   * 
   * 
   * 
   */
  setValor(){

    let vale_presente_valor = null;

    if(this.servico != null){
      if(this.servico.vale_presente){
        vale_presente_valor = this.servico.vale_presente_valor;
        this.valor = vale_presente_valor;
      }
    }
    
    let number:any = vale_presente_valor != null ? vale_presente_valor.toString() : this.valor.toString();

    number = number.split(".");

    this.valor1 = number[0];

    if(typeof(number[1]) != "undefined"){

      this.valor2 = number[1];

    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setValor();
  }

}
