import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'evento-form-agendamento',
  templateUrl: './evento-form-agendamento.component.html',
  styleUrls: ['./evento-form-agendamento.component.scss']
})
export class EventoFormAgendamentoComponent implements OnInit {

  @Input("evento") evento:any = {};
  public dataItem:any         = {}; 
  public loader               = false;

  constructor(
    private api: ApiService,
    private app: AppService
  ){ }
  
  /**
   * 
   * Envia os dados
   * 
   */
  enviar(){

    try{
      
      this.loader = true; 

      this.api.evento().contato(this.dataItem).subscribe(response => {

        this.loader = false;   

        if(response.status == 1){

          this.app.info("Obrigado pelo seu contato. Em breve retornaremos.","success");
          this.initDataItem();  

        }else{
          
          this.app.info("Houve um erro. Tente novamente ou entre em contato com nossa equipe.","danger");
        
        } 

      },(response) => {

        this.loader = false;

        let error = this.api.formatError(response);

        if(error.status == 422){ 
          this.app.info(error.message,"danger");
        }else{
          this.app.info(error.message,"danger",null,null,"Houve um erro");
        }

      });

    }catch(e){

      this.loader = false;

    }

    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      telefone: "",
      email: "",
      mensagem: "",
      evento_id: this.evento.id 
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.initDataItem();
  }

}
