import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjetoModal } from '../projeto-modal/projeto-modal.component';

@Component({
  selector: 'projeto-item',
  templateUrl: './projeto-item.component.html',
  styleUrls: ['./projeto-item.component.scss']
})
export class ProjetoItemComponent implements OnInit {

  @Input("data") projeto:any = {};

  constructor(
    public dialog: MatDialog
  ) { }

  /***
   * 
   * Open Projeto
   * 
   */
  openProjeto(){

    this.dialog.open(ProjetoModal,{
      width: "100%",
      height: "100%",
      data: {
        link: this.projeto.link_site
      }
    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{}

}
