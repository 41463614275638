import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'evento-item',
  templateUrl: './evento-item.component.html',
  styleUrls: ['./evento-item.component.scss']
})
export class EventoItemComponent implements OnInit {

  @Input("data") data:any = {};
  public link  = null;

  constructor() { }

  /**
   * 
   * Set link
   * 
   */
  setLink(){

    this.link = "/eventos/"+this.data.apelido_categoria+"/"+this.data.apelido;

  } 
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit():void{
    this.setLink();
  }
  

}
