import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'modal-consultor',
  templateUrl: './modal-consultor.component.html',
  styleUrls: ['./modal-consultor.component.scss']
})
export class ModalConsultor implements OnInit {

  public dataItem:any  = {};
  public loader        = false;
  public labelMensagem = "";

  constructor(
    public dialog: MatDialogRef<ModalConsultor>,
    @Inject(MAT_DIALOG_DATA) public data,
    private app: AppService,
    private api: ApiService 
  ){ 
  
  }
  /**
   * 
   * Envia a solicitação
   * 
   */
  enviar(){

    try{

      this.loader = true;

      this.api.contato().consultor(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          if(this.dataItem.tipo == 5){
            this.app.info("Obrigado pela sua sugestão.","success");
          }else{
            this.app.info("Obrigado pelo seu contato. Em breve retornaremos.","success");
          }

          this.initDataItem();

        }else{

          this.app.info("Houve um erro. Tente novamente ou entre em contato com nossa equipe.","danger");

        }

      }, (response) => {

        this.loader = false;

        let error = this.api.formatError(response);

        if(error.status == 422){ 
          this.app.info(error.message,"danger");
        }else{
          this.app.info(error.message,"danger",null,null,"Houve um erro");
        }

      });

    }catch(e){

      this.app.info(e.message,"danger")

    }

  }
  /**
   * 
   * Inicialza o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      mensagem: "",
      tipo: 3 
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{}

}
