import { Pipe, PipeTransform, ElementRef } from '@angular/core';

@Pipe({
  name: 'maquinaEscrever'
})
export class MaquinaEscreverPipe implements PipeTransform {

  public _elemento:ElementRef = null; 

  constructor(elemento:ElementRef){
    this._elemento = elemento;
  }
  /**
   * 
   * @param value 
   * @param args 
   * 
   */
  transform(value: any): any {
    
    const textoArray = value.split('');
    this._elemento.nativeElement.innerHtml = ' ';

    textoArray.forEach(function(letra,i){   
      
      setTimeout(function(){
        this._elemento.nativeElement.innerHtml += letra;
      },75*i)
    
    });

  }

}
