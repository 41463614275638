import { Component, OnInit, Input, ViewChild, Output, ElementRef, EventEmitter, Renderer2, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, DefaultValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

declare var $:any; 
const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ComboSelect),
  multi: true,
};

@Component({
  selector: 'combo-select',
  templateUrl: './combo-select.component.html',
  styleUrls: ['./combo-select.component.scss'],
  providers: [
    VALUE_ACCESSOR
  ]
})
export class ComboSelect extends DefaultValueAccessor implements OnInit {

  @Input("label") label             = null;
  @Input("placeholder") placeholder = "Selecione...";
  @Input("value") value             = null;
  @Input("data") data        = null;
  @Input("url") url          = null;
  @Input("text") text        = null;
  @Input("tipo") tipo        = 1;
  @Input("ngModel") ngModel  = null; 
  @Input("reload") reload    = new EventEmitter();; 
  @ViewChild("select") select:ElementRef;
  @Output("changeValue") changeValue = new EventEmitter();
  public comboCtrl        = new FormControl();
  public comboFilter:Observable<any>;
  public loader           = false;
  public response         = {
    data: [],
    status: null,
    message: ""
  };
  public dataCombo = [];
  
  constructor(
    private renderer2: Renderer2,
    private el: ElementRef,
    private api:ApiService,
    private app: AppService
  ){ 

    super(renderer2,el,false);

  }
  
  /**
   * 
   * Retorna os dados para o ComboBox
   * 
   * 
   */
  getData(){

    try{

      this.loader = true;  
      this.api.request().get(this.url).subscribe(response => {

        this.loader = false;
        
        if(response.status == 1){

          this.dataCombo       = response.data;
          this.response.status = 1;

          setTimeout(() => {
            $(this.select.nativeElement).select2("open");
          },100);
                  
        }else{

          this.response = {
            data: [],
            status: 500,
            message: response.data
          }

        }  

      },(response) => {

        this.loader = false;

        let error     = this.app.formatError(response);
        this.response = {
          data: [],
          status: 500, 
          message: error.message
        };

      });
  

    }catch(e){

      this.loader = false;
    
    }

  }
   /**
   * 
   * Get dataItem
   * 
   */
  _getDataItem(value){

    let data = null;

    for(let index = 0;index < this.dataCombo.length;index++) {

      if(value == this.dataCombo[index]["value"]){
        
        data  =  this.dataCombo[index];       
        break;

      }
      
    }

    return data;

  }
  /***
   * 
   * Verifica a mudança
   * 
   */
  _change(value=null){

    try{
      
      let item = this._getDataItem(value);
          
      if(item != null){
        this.changeValue.emit(item);
      }else{
        this.changeValue.emit({
          value: null,
          text: null
        }); 
      }

    }catch(e){
      
    }

  }
  /**
  * 
  * 
  * 
  */
  _getData(){
    
    if(this.response.status != 1){
      $(this.select.nativeElement).select2("close");
      this.getData();
    }

  }
  initSelect(){

    let self = this;
    
    $(this.select.nativeElement).val(this.value).select2({
      allowClear: false
    });
    $(this.select.nativeElement).on("select2:select", function (e) {

      let value = $(this).val();        
      
      self.value = value;
      self._change(value); 
      
      
       
  
    });

  }
  /***
   * 
   * On Data
   * 
   */
  onData(){

    this.reload.subscribe(data => {

      this.getData(); 

    });

  }
  /**
   * 
   * Inicializando as Funções
   * 
   */
  ngOnInit(){

  }
  ngAfterViewInit(): void {
    
    this.initSelect(); 
    this.onData();

  }

}
