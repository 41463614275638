import { Component, OnInit, Inject, Sanitizer } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'projeto-modal',
  templateUrl: './projeto-modal.component.html',
  styleUrls: ['./projeto-modal.component.scss']
})
export class ProjetoModal implements OnInit {

  public dataItem:any  = {};
  public loader        = false;
  public labelMensagem = "";
  public link          = null;

  constructor(
    public dialog: MatDialogRef<ProjetoModal>,
    @Inject(MAT_DIALOG_DATA) public data,
    public sanitize: DomSanitizer
  ){ 
    this.link = this.sanitize.bypassSecurityTrustResourceUrl(this.data.link);
  } 
  /**
   * 
   * Close
   * 
   */
  close(){

    this.dialog.close();

  }
  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{}

}
