import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDuracao'
})
export class FormatDuracaoPipe implements PipeTransform {

  transform(value:any, ...args: unknown[]): unknown {
    
    let data    = value.split(":"); 
    let horas   = parseInt(data[0]);
    let minutos = parseInt(data[1]);
    
    if(horas > 1 && minutos == 0){ 
      return parseInt(data[0]) + " HORAS";
    }else if(horas == 1 && minutos == 0){
      return parseInt(data[0]) + " HORA";
    }else if(horas > 1 && minutos > 0){
      return parseInt(data[0]) + " HORAS e "+minutos+" MINUTO(S)";
    }else{
      return parseInt(data[0]) + " HORA e "+minutos+" MINUTO(S)";
    } 

  }

}
