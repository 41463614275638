import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'produto-foto',
  templateUrl: './produto-foto.component.html',
  styleUrls: ['./produto-foto.component.scss']
})
export class ProdutoFotoComponent implements OnInit {

  @Input("produto") produto; 
  public foto = null;
  public alt  = null; 

  constructor() { }

  /**
   * 
   * Seta os dados da foto do produto
   * 
   */
  setData(){

    if(this.produto.foto == null){ 

      this.foto = "/assets/images/sao-paulo-ecoturismo.jpg";
      this.alt  = this.produto.titulo; 

    }else{

      this.foto = "/images/produtos/fotos/produto-"+this.produto.id+"/"+this.produto.foto;
      this.alt  = this.produto.foto_alt;

    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
    this.setData();
  }

}
