export class RouteBase {

    public static API_BASE = "/api/site";

    public static MODULO = {
        modulos: RouteBase.API_BASE+"/modulo/modulo", // RESOURCE
        banner: RouteBase.API_BASE+"/modulo/modulo-banner",
        unidade: RouteBase.API_BASE+"/modulo/unidade",
        fale_conosco: RouteBase.API_BASE+"/modulo/contato/fale-conosco",
        dica_roteiro: RouteBase.API_BASE+"/modulo/dica-roteiro",
        newsletter: RouteBase.API_BASE+"/modulo/newsletter",
        contato_consultor: RouteBase.API_BASE+"/modulo/contato/consultor",
        contato: RouteBase.API_BASE+"/modulo/contato/contato",
        contato_sugestao: RouteBase.API_BASE+"/modulo/contato/sugestao",
    }
    public static CONTEUDO = {
        page: RouteBase.API_BASE+"/conteudo/page"
    }
    public static PRODUTO = {
        produtos: RouteBase.API_BASE+"/componentes/produto/produtos",
        produto: RouteBase.API_BASE+"/componentes/produto/produto",
        search: RouteBase.API_BASE+"/componentes/produto/search",
        avaliacao: RouteBase.API_BASE+"/componentes/produto/avaliacao",
        avaliacoes: RouteBase.API_BASE+"/componentes/produto/avaliacao", 
        cidade: RouteBase.API_BASE+"/componentes/cidade",
        categorias: RouteBase.API_BASE+"/componentes/produto/categorias",
        aeroporto: {
            combo: RouteBase.API_BASE+"/componentes/produto/aeroporto/combo", 
        },
        cia_aerea: {
            combo: RouteBase.API_BASE+"/componentes/produto/cia-aerea/combo",
        },
        contato: RouteBase.API_BASE+"/componentes/produto/contato",
        destino_produtos: RouteBase.API_BASE+"/componentes/produto/destino/produtos",
        regiao_produtos: RouteBase.API_BASE+"/componentes/produto/regiao/produtos",
        solicitacao_reserva: RouteBase.API_BASE+"/componentes/produto/solicitacao-reserva"
    }
    public static EVENTO = {
        search: RouteBase.API_BASE+"/componentes/evento/search",
        evento: RouteBase.API_BASE+"/componentes/evento/evento",
        contato: RouteBase.API_BASE+"/componentes/evento/contato"   
    }
    public static ORCAMENTO = {
        categoria: {
            combo: RouteBase.API_BASE+"/componentes/orcamento/categoria/combo"
        },
        orcamento: { 
            url: RouteBase.API_BASE+"/componentes/orcamento/orcamento"
        }
    }
    public static IBGE = {
        estado: {
            combo: RouteBase.API_BASE+"/ibge/estado/combo", 
        },
        cidade: {
            combo: RouteBase.API_BASE+"/ibge/cidade/combo",
        }
    }
    
}
