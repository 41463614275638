import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePage implements OnInit {

  constructor(
    private app: AppService,
    private data: DataService
  ){ }

  /**
   * 
   * Seta o header
   * 
   */
  setHeader(){

    let config = this.data.getConfiguracao();

    this.app.setMetaDados(config.meta_title,config.meta_description);

  }
  /***
   * 
   * Inicializa Iniciliazação
   * 
   * 
   **/
  ngOnInit():void{
    this.app.toTop(); 
    this.setHeader();
  }


}
