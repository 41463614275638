import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

declare var instgrm:any;

@Component({
  selector: 'modulo-instagram-feed',
  templateUrl: './instagram-feed.component.html',
  styleUrls: ['./instagram-feed.component.scss']
})
export class InstagramFeedComponent implements OnInit,AfterViewInit {

  @Input("data") data         = null; 
  @Input("template") template = null;  

  constructor() { }
  
  /**
   * 
   * Load
   * 
   */
  load(){

    try{


      instgrm.Embeds.process();


    }catch(e){
      
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(): void {
    this.load();
  }
  /**
   * 
   * After view loaded
   * 
   */
  ngAfterViewInit(): void {
    
    setTimeout(() => {

      this.load();

    },500);

  }

}
