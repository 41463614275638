import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-managetour',
  templateUrl: './managetour.component.html',
  styleUrls: ['./managetour.component.scss']
})
export class ManagetourComponent implements OnInit {

  @Input("data") data:any         = {};
  @Input("template") template:any = {};
  public itens  = [];
  
  constructor(){ }

  /**
   * 
   * Set itens
   * 
   */
  setItens(){

    this.itens.push({
      foto: "/images/managetour/sistema-de-gestao.png",
      titulo: "Sistema de Gestão",
      link: "https://managetour.com.br/nossos-servicos/sistema-de-gestao"
    });
    this.itens.push({
      foto: "/images/managetour/ecommerce.png",
      titulo: "Loja Virtual",
      link: "https://managetour.com.br/nossos-servicos/ecommerce"
    });
    this.itens.push({
      foto: "/images/managetour/aplicativo-vendas.png",
      titulo: "Aplicativo de Vendas",
      link: "https://managetour.com.br/nossos-servicos/aplicativo"
    });

  }
  /*
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit():void{
    this.setItens();
  }

}
