import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'evento-form-search',
  templateUrl: './evento-form-search.component.html',
  styleUrls: ['./evento-form-search.component.scss']
})
export class EventoFormSearchComponent implements OnInit {

  @Input("categorias") categorias = [];
  @Input("nome")  nome = "";
  @Output("updateData") updateData = new EventEmitter();
  public dataItem:any  = {};
  public loader        = false;
  public showContent   = false;

  constructor(
    private api: ApiService
  ){ 
    this.initDataItem();
  }

  /***
   * 
   * Faz a busca de eventos com os termos
   * 
   */
  pesquisar(){

    try{

      this.loader = true;

      this.api.evento().search(this.dataItem).subscribe((response) => {

        this.loader = false;

        if(response.status == 1){ 
          this.updateData.emit(response.data);
        }  

      },(response) => {

        this.loader = false;
        
      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * @param data 
   * 
   */
  _changeDataInicio(event){

    this.dataItem.data_inicio = event.target.value;

  }
  _changeDataInicioPicker(event){

    if(event.value === null || event.value === "null"){
      this.dataItem.data_inicio = "";
      return false; 
    }
    let date      = new Date(event.value);
    let day       = date.getDate();
    let month:any = date.getMonth()+1;
    month         = month < 10 ? "0"+month : month;
    let year      = date.getFullYear(); 
    
    this.dataItem.data_inicio = day+"/"+month+"/"+year;


  }
  _changeDataFim(event){

    this.dataItem.data_fim = event.target.value;

  }
  _changeDataFimPicker(event){

    if(event.value === null || event.value === "null"){
      this.dataItem.data_fim = "";
      return false;
    }
    let date      = new Date(event.value);
    let day       = date.getDate();
    let month:any = date.getMonth()+1;
    month         = month < 10 ? "0"+month : month;
    let year      = date.getFullYear(); 
    
    this.dataItem.data_fim = day+"/"+month+"/"+year;


  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: this.nome, 
      evento_categoria_id: "",
      data_inicio: "",
      data_fim: "" 
    }

  }
  /**
   * 
   * Show content
   * 
   */
  _showContent(){ 

    this.showContent = this.showContent == true ? false : true; 

  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit():void{}

}
