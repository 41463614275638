import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'evento-page',
  templateUrl: './evento-page.component.html',
  styleUrls: ['./evento-page.component.scss']
})
export class EventoPage implements OnInit,AfterViewInit,OnDestroy {

  public loader       = false;
  public evento       = null;
  public fotos        = [];
  public relacionados = [];
  public response      = {
    data: "",
    status: null
  }
  public itensBreadcrumbs        = [];
  public apelido                 = null;
  public mySubscription:Subscription;
  public destroyed               = new Subject<any>();
  public destroy                 = false;
  public banner                  = null;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private app: AppService,
    private dialog: MatDialog
  ) { 

    this.apelido  = typeof(this.route.params["_value"].apelido) != "undefined" ? this.route.params["_value"].apelido : null;

  }
  /**
   * 
   * Retorna os dados do produto baseado no apelido
   * 
   * 
   */
  getData(){

    try{

      this.loader           = true;
      this.response.status  = null; 
      this.itensBreadcrumbs = [];
 
      this.api.evento().evento(this.apelido).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          if(response.data.evento == null){
            this.router.navigateByUrl("/"); 
          }

          this.evento            = response.data.evento;
          this.banner            = this.evento.banner;  
          this.fotos             = response.data.fotos; 
          this.relacionados      = response.data.relacionados;
          this.response.status   = 1; 
          
          this.setItensBreadcrumbs();
          this.app.setMetaDados(this.evento.meta_title,this.evento.meta_description);
          
          setTimeout(() => {
            this.app.toTop();
          },200);

        }else{
          this.response = response;
        }

      },(response) => {

        this.loader = false;
        let error   = this.app.formatError(response);

        this.response = {
          data: error.message,
          status: 500
        }

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Set Breadcrumbs
   * 
   */
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Eventos",
      routerLink: "/eventos"
    });
    this.itensBreadcrumbs.push({
      text: this.evento.nome
    });


  }
  /**
   * 
   * After loader view
   * 
   */
  ngAfterViewInit(): void {
    
    if(this.mySubscription){
      
      this.destroy         = true;
      this.mySubscription.unsubscribe(); 
    
    }

  }
  ngOnDestroy(): void {
    
    if(this.mySubscription){
      this.destroy         = true;
      this.mySubscription.unsubscribe(); 
    }

  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit(): void {
   
    this.app.toTop();
    this.getData();

    this.mySubscription = this.router.events.pipe(
      filter((event:RouterEvent) => event instanceof onanimationend),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      
      if(!this.destroy){
        this.apelido   = this.route.params["_value"].apelido; 
        this.getData();
      }  

    });
  }

}
