import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'projetos-page',
  templateUrl: './projetos-page.component.html',
  styleUrls: ['./projetos-page.component.scss']
})
export class ProjetosPage implements OnInit {

  public loader  = false;
  public apelido = "projetos"; 
  public response = {
    data: "",
    status: null
  }
  public page:any   = {};
  public params:any = {};
  public itensB = [{
    text: "Projetos" 
  }];
  public fotos    = [];
  public projetos = []; 

  constructor(
    private app: AppService,
    private api: ApiService
  ){ }

  /**
   * 
   * Retorna os dados da página
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.conteudo().page(this.apelido).subscribe(response => {
        
        this.loader = false;   

        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
            
            this.response = { 
              data: "",
              status: 1
            }

            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.fotos     = response.data.page.fotos;
            this.params    = this.page.params != null ? JSON.parse(this.page.params) : {};
            this.projetos  = response.data.page.projetos; 
             
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description); 
              
          }  
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
        this.app.toTop(); 

      },(response) => {

        this.loader = false;
        this.app.toTop(); 
        
      
      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Inicilaiza as Funções
   * 
   */
  ngOnInit():void{
    this.getData();
  }

}
