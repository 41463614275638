import { Component, OnInit, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { RouteBase } from 'src/app/routes/base';

@Component({
  selector: 'orcamento-page',
  templateUrl: './orcamento-page.component.html',
  styleUrls: ['./orcamento-page.component.scss']
})
export class OrcamentoPage implements OnInit {

  public loader  = false;
  public apelido = "orcamento"; 
  public response = {
    data: "",
    status: null
  }
  public page:any   = {};
  public params:any = {};
  public itensB = [{
    text: "Orçamentos" 
  }];
  public fotos           = [];
  public projetos        = []; 
  public dataItem:any    = {};
  public content         = 1; 
  public dataNecessidade = [];
  public comboCategoria  = null;
  public comboEstado     = null;
  public comboCidade     = null;
  public estadoId        = null;
  public reloadCidade    = new EventEmitter();

  constructor(
    private app: AppService,
    private api: ApiService,
    private validator: ValidatorService
  ){ }

  /**
   * 
   * Retorna os dados da página
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.conteudo().page(this.apelido).subscribe(response => {
        
        this.loader = false;   

        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
            
            this.response = { 
              data: "",
              status: 1
            }

            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.fotos     = response.data.page.fotos;
            this.params    = this.page.params != null ? JSON.parse(this.page.params) : {};
            this.projetos  = response.data.page.projetos; 
             
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description); 
              
          }  
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
        this.app.toTop(); 

      },(response) => {

        this.loader = false;
        this.app.toTop(); 
        
      
      });

    }catch(e){

      this.loader = false;

    }

  }
  /***
   * 
   * Init dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      empresa: "",
      orcamento_categoria_id:  null,
      estado_id: null,
      cidade_id: null,
      descricao: ""
    }
    this.content = 1;

  }
  /**
   * 
   * Set content
   * 
   */
  setContent(value){ 

     if(parseInt(value) == 2){

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.nome,"nome","- Informe o seu nome.");
      this.validator.isEmpty(this.dataItem.email,"email","- Informe o seu e-mail.");
      this.validator.isEmail(this.dataItem.email,"email","- O e-mail informado é inválido.");
      this.validator.isEmpty(this.dataItem.telefone,"telefone","- Informe o seu telefone.");

      if(!this.validator.passes()){
        
        let errors = this.validator.getMessagesHtml();
        this.app.info(errors,"danger");
        return false;
      
      }

    }else if(parseInt(value) == 3){

      this.validator.clear(); 
      this.validator.isEmpty(this.dataItem.empresa,"empresa","- Informe a sua empresa.");
      this.validator.isEmpty(this.dataItem.orcamento_categoria_id,"orcamento_categoria_id","- Informe o que você estlá procurando.");
      this.validator.isEmpty(this.dataItem.estado_id,"estado_id","- Informe o seu estado."); 
      this.validator.isEmpty(this.dataItem.cidade_id,"cidade_id","- Informe a sua cidade.");

      if(!this.validator.passes()){ 
        
        let errors = this.validator.getMessagesHtml();
        this.app.info(errors,"danger");
        return false;
      
      } 

    }

    this.content = value;

  }
  /**
   * 
   * Enviar
   * 
   */
  enviar(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.descricao,"descricao","Descreva a sua necessidade.")

      if(!this.validator.passes()){

        let errors = this.validator.getMessagesHtml();
        this.app.info(errors,"danger")
        return false;

      }

      this.loader = true;

      this.api.orcamento().criar(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.app.info("Recebemos os seus dados. Em breve entraremos em contato.","success"); 
          this.initDataItem(); 

        }else{

          this.app.info("Houve um problema. Tente novamente ou entre em contato com nossa equipe.","danger"); 

        } 
        
      },(response) => {  

        this.loader = false;
        let error   = this.api.formatError(response);
        this.app.info(error.message,"danger");

      });


    }catch(e){

      this.loader = false;
      this.app.info(e.message,"danger"); 

    }


  }
  /***
   * 
   * Seta data categoria
   * 
   */
  setDataNecessidade(){


  }
  /**
   * 
   * 
   * 
   */
  setValue(data,index){

    this.dataItem[index] = data.value; 

    if(index == "estado_id"){

      this.comboCidade        = RouteBase.IBGE.cidade.combo+"/"+data.value; 
      this.dataItem.cidade_id = null;
      this.reloadCidade.emit(null);

    } 

  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit():void{
    this.getData();
    this.initDataItem(); 
    this.setDataNecessidade();
    this.comboCategoria = RouteBase.ORCAMENTO.categoria.combo;
    this.comboEstado    = RouteBase.IBGE.estado.combo;
  }

}
