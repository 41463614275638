import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-eventos-em-destaque',
  templateUrl: './eventos-em-destaque.component.html',
  styleUrls: ['./eventos-em-destaque.component.scss']
})
export class EventosEmDestaqueComponent implements OnInit {

  @Input("data") data:any         = null;
  @Input("template") template:any = null;

  constructor(){ }  

  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(): void {
  }

}
