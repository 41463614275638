import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd,Event as NavigationEvent, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { AppService } from 'src/app/services/app.service';

declare var $:any;

@Component({
  selector: 'modulo-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input("classCustom") classCustom:any;
  @Input("mobile") mobile = false;
  public subMenu          = false;
  public showMenu         = false;
  public categorias       = []; 

  constructor(
    public router: Router,
    public data: DataService,
    private app: AppService
  ){ }

  /**
   * 
   * 
   */
  _subMenu(){

    this.subMenu = this.subMenu == true ? false : true;

  }
  /**
   * 
   * On Events
   * 
   */
  onEnvets(){

    this.router.events.forEach((event:NavigationEvent) =>{
      
      if(event instanceof NavigationEnd){
        
        this.subMenu = false; 
        
        if(this.classCustom == "menu-mobile"){

          this.showMenu = false;
          $("body").removeClass("open-menu");

        }

      }
              
    });

    this.app.setCategoriaMenu.subscribe(data => {

      this.categorias = data;

    });

  }
  /**
   * 
   * Button open
   * 
   */
  open(){

    this.showMenu = this.showMenu == true ? false : true; 

    if(this.showMenu == true){
      $("body").addClass("open-menu");
    }else{
      $("body").removeClass("open-menu");
    }

  }
  /**
   * 
   * Seta as categorias
   * 
   */
  setCategorias(){

    this.categorias = this.data.getCategoriasMenu();    

  }
  /**
   * 
   * Iniciliza 
   * 
   */
  ngOnInit(){
    this.onEnvets();
    this.setCategorias();
  }

}
