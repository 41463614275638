import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'produto-form-agendamento',
  templateUrl: './produto-form-agendamento.component.html',
  styleUrls: ['./produto-form-agendamento.component.scss']
})
export class ProdutoFormAgendamento implements OnInit {

  public dataItem:any = {};
  public servico:any  = {};
  public loader       = false;

  constructor(
    public dialog:MatDialogRef<ProdutoFormAgendamento>,
    @Inject(MAT_DIALOG_DATA) public data,
    public app: AppService,
    public api: ApiService
  ){ 

  }
  /**
   * 
   * Enviar
   * 
   */
  enviar(){

    try{

      this.loader = true;
  
      this.api.produto().contato(this.dataItem).subscribe(response => {

        this.loader = false;
        
        if(response.status == 1){

          this.app.info("Obrigado pelo seu contato. Em breve retornaremos.","success");
          this.initDataItem(); 

        }else{

          this.app.info("Houve um erro. Tente novamente ou entre em contato com nossa equipe.","danger");

        }  

      },(response) => {

        this.loader = false;   
        
        let error = this.api.formatError(response);

        if(error.status == 422){ 
          this.app.info(error.message,"danger");
        }else{
          this.app.info(error.message,"danger",null,null,"Houve um erro");
        }

      });

    }catch(e){

      this.loader = false;
      this.app.info(e.message,"danger");

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      mensagem: "",
      produto_id: this.data.servico.id
    }


  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.initDataItem();
  }


}
