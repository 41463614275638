import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GtagService } from 'src/app/services/gtag.service';

@Component({
  selector: 'produto-item',
  templateUrl: './produto-item.component.html',
  styleUrls: ['./produto-item.component.scss']
})
export class ProdutoItemComponent implements OnInit{

  @Input("data") produto:any            = {};
  @Input("categoria") categoria:any     = null;
  @Input("index") index:any             = 0; 
  @Input("nome_lista") nome_lista:any   = "Página de Serviços";
  @Input("classCustom") classCustom:any = "";
  public link                           = null;
  public defaultImage                   = "/images/sem-foto/gramado-premium-800-530.jpg";

  constructor(
    private gtag: GtagService,
    private route: Router
  ){ 
    
  }
  /**
   * 
   * Seta o link para o detalhe do produto
   * 
   */
  setLink(){

    if(this.categoria ==  null){
      this.link = "/servicos/"+this.produto.categoria_apelido+"/"+this.produto.apelido;
    }else{
      this.link = "/servicos/"+this.categoria.apelido+"/"+this.produto.apelido;
    }  

  }
  /**
   * 
   * On click
   * 
   */
  onClick(){

    this.gtag.selectProduct({
      id: this.produto.id,
      nome: this.produto.nome,
      categoria: this.produto.categoria_titulo,
      list_name: this.nome_lista,
      list_position: this.index+1 
    });
    this.route.navigateByUrl(this.link);

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setLink();
  }
  

}
