import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-passeios-em-destaque',
  templateUrl: './passeios-em-destaque.component.html',
  styleUrls: ['./passeios-em-destaque.component.scss']
})
export class PasseiosEmDestaqueComponent implements OnInit {

  @Input("data") data:any         = null;
  @Input("template") template:any = null;  

  constructor() { }

  /**
   * 
   * Inicialza as Funções
   * 
   */
  ngOnInit():void{}

}
