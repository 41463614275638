import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePage } from './pages/home-page/home-page.component';
import { ModulosResolver } from './resolvers/modulos.resolver'; 
import { ContatoPage } from './pages/contato-page/contato-page.component';
import { SobrePage } from './pages/sobre-page/sobre-page.component';
import { ProjetosPage } from './pages/projetos-page/projetos-page.component';
import { OrcamentoPage } from './pages/orcamento-page/orcamento-page.component';

const routes: Routes = [{
  path: '',    
  component: HomePage,
  resolve:{   
    modulos: ModulosResolver
  }  
},{
  path: 'projetos',   
  component: ProjetosPage,  
  resolve:{   
    modulos: ModulosResolver
  }  
},{
  path: 'contato',   
  component: ContatoPage, 
  resolve:{   
    modulos: ModulosResolver
  }  
},{
  path: 'orcamentos',   
  component: OrcamentoPage, 
  resolve:{   
    modulos: ModulosResolver
  }  
},{
  path: 'sobre',   
  component: SobrePage,  
  resolve:{   
    modulos: ModulosResolver 
  }  
},{
  path: '**',
  pathMatch: 'full', 
  redirectTo: ''
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
