import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

declare var $:any;

@Component({
  selector: 'galeria-light',
  templateUrl: './galeria-light.component.html',
  styleUrls: ['./galeria-light.component.scss']
})
export class GaleriaLightComponent implements OnInit,AfterViewInit {
  
  @Input("fotos") fotos = [];
  @ViewChild("galeria") galeria:ElementRef;
  @Input("classCustom") classCustom = "";
  public defaultImage  = "/images/produto/sem-foto/brocker.jpg";

  constructor(){ }

  initGallery(){

    $(this.galeria.nativeElement).lightGallery({
      thumbnail:true
    }); 

  }
  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
  
  }
  /**
   * 
   * Init funções
   * 
   */
  ngAfterViewInit(): void {
      this.initGallery();    
  }

}
