import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'contato-page',
  templateUrl: './contato-page.component.html',
  styleUrls: ['./contato-page.component.scss']
})
export class ContatoPage implements OnInit {

  public loader  = false;
  public apelido = "contato"; 
  public response = {
    data: "",
    status: null
  }
  public page:any     = {};
  public params:any   = {};
  public dataItem:any = {}
  public message      = "Carregando. Aguarde!";
  public itensB       = [{
    text: "Contato"
  }];

  constructor(
    private app: AppService,
    private api: ApiService
  ) { }

  /**
   * 
   * Retorna os dados da página
   * 
   */
  getData(){

    try{

      this.message = "Enviando. Aguarde!";  
      this.loader  = true;

      this.api.conteudo().page(this.apelido).subscribe(response => {

        this.loader = false;   

        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
            
            this.response = {  
              data: "",
              status: 1
            }

            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.params    = this.page.params != null ? JSON.parse(this.page.params) : {};
            
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description); 
              
          }
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500 
          }
        }
        setTimeout(() => {
          this.app.toTop();
        },500);
        

      },(response) => {

        this.loader = false;
        setTimeout(() => {
          this.app.toTop(); 
        },500);
      
      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Enviar
   * 
   */
  enviar(){

    try{

      this.loader = true;

      this.api.contato().contato(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.app.info("Obrigado pelo seu contato. Em breve retornaremos.","success");
          this.initDataItem(); 
          
        }else{

          this.app.info("Aconteceu algo inesperado. Tente novamente.","danger",null,null,"Houve um erro");

        }

      }, (response) => {

        this.loader = false;

        let error = this.api.formatError(response);

        if(error.status == 422){ 
          this.app.info(error.message,"danger");
        }else{
          this.app.info(error.message,"danger",null,null,"Houve um erro");
        }

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * 
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      mensagem: "",
      assunto: "",
      tipo: 4
    }

  }
  /**
   * 
   * Inicilaiza as Funções
   * 
   */
  ngOnInit():void{
    this.getData();
    this.initDataItem();
  }

}
