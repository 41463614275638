import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {

  @ViewChild("header") header:ElementRef;
  @ViewChild("menuMobile") menuMobile:ElementRef;

  constructor(){ } 
  
  /**
   * 
   * Seta a Class Fixed para o topo
   * 
   */
  setTopFixed(){

    let header     = this.header.nativeElement;
    let menuMobile = this.menuMobile.nativeElement;

    $(window).scroll(function(){ 
      
      var fromTop = $(this).scrollTop();

      if(fromTop > 100 && fromTop < 250){
        menuMobile.classList.add("hide"); 
      }else{
        menuMobile.classList.remove("hide"); 
      }
      if(fromTop > 250){
        header.classList.add("animated-two","slideInDown","fixed"); 
      }else{
        header.classList.remove("fixed","animated-two","slideInDown");
      }
      

    });

  }
  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    
  }
  ngAfterViewInit(): void {
    this.setTopFixed();
  }

}
