import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'empresas-parceiras-partial',
  templateUrl: './empresas-parceiras-partial.component.html',
  styleUrls: ['./empresas-parceiras-partial.component.scss']
})
export class EmpresasParceirasPartialComponent implements OnInit {

  constructor() { }


  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{}

}
