import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'modulo-contato-consultor',
  templateUrl: './contato-consultor.component.html',
  styleUrls: ['./contato-consultor.component.scss']
})
export class ContatoConsultorComponent implements OnInit {

  @Input("data") data:any = {};
  public dataItem:any     = {};
  public loader           = false;

  constructor(
    private api: ApiService,
    private app: AppService 
  ){ }

  /**
   * 
   * Enviar
   * 
   */
  enviar(){

    try{

      this.loader = true; 

      this.api.contato().consultor(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.app.info("Obrigado pelo seu contato. Em breve retornaremos.","success");
          this.initDataItem();
          
        }else{

          this.app.info("Aconteceu algo inesperado. Tente novamente.","danger",null,null,"Houve um erro");

        }

      },(response) => {

        this.loader = false;

        let error = this.api.formatError(response);

        if(error.status == 422){ 
          this.app.info(error.message,"danger");
        }else{
          this.app.info(error.message,"danger",null,null,"Houve um erro");
        }

      });


    }catch(e){

      this.loader = false;
      this.app.info(e.message,"danger");

    }

  }
  /**
   * 
   * Incializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      telefone: "",
      email: "",
      mensagem: "",
      tipo: 3
    }

  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit():void{
    this.initDataItem();
  }

}
