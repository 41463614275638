import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-logo-brasao',
  templateUrl: './logo-brasao.component.html',
  styleUrls: ['./logo-brasao.component.scss']
})
export class LogoBrasaoComponent implements OnInit {

  @Input("data") data:any         = {}; 
  @Input("template") template:any = null; 
  public logo  = null;
  
  constructor() { }

  /**
   * 
   * Seta a logo
   * 
   */
  setLogo(){

    this.logo = "/images/modulo/logo-brasao/"+this.data.params.imagem;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.setLogo();
  }

}
