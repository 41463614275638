import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalConsultor } from 'src/app/components/modal/modal-consultor/modal-consultor.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'modulo-evento-estrutura',
  templateUrl: './evento-estrutura.component.html',
  styleUrls: ['./evento-estrutura.component.scss']
})
export class EventoEstruturaComponent implements OnInit {

  @Input("data") data:any         = {};
  @Input("template") template:any = {};
  public url_video                = null;

  constructor(
    private dialog: MatDialog,
    public sanitizer: DomSanitizer
  ){}
  
  /**
   * 
   * On click  
   * 
   */
  _onClick(){

    this.dialog.open(ModalConsultor,{ 
      width: "450px",
      height: "auto",
      panelClass: 'no-padding'
    });

  }
  /**
   * 
   * Set video
   * 
   */
  setVideo(){
 
    this.url_video = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.params.url_video);

  }
  /**
   * 
   * Inciializa as Funções
   * 
   */
  ngOnInit():void{
    this.setVideo();
  } 

}
