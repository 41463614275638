import { Component, OnInit, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'modulo-contatos',
  templateUrl: './contatos.component.html',
  styleUrls: ['./contatos.component.scss']
})
export class ContatosComponent implements OnInit {

  @Input("data") data:any         = {};
  @Input("template") template:any = null;
  public prefixo                  = "web";
  public numero                   = "";  
  
  constructor(
    private deviceService: DeviceDetectorService
  ){}

  /**
   * 
   * Open WhatsApp
   * 
   */
  open(){

    window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+this.numero,"__blank");
      
  }
  /**
   * 
   * Open insta
   * 
   */
  openInsta(){ 
    window.open(this.data.params.instagram_link,"__blank");

  }
  /**
   * 
   * Seta o número
   * 
   */
  setNumero(){
    
    let telefone = this.data.params.whatsapp.replace(".","").replace("(","").replace(")","").replace("-","").replace(" ","");
    this.numero = "55"+telefone;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(): void {
    this.setNumero();
    this.prefixo = this.deviceService.isDesktop() ? "web" : "api";
  }

}
