import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare var window: any;

@Component({
  selector: 'modulo-localizacao',
  templateUrl: './localizacao.component.html',
  styleUrls: ['./localizacao.component.scss']
})
export class LocalizacaoComponent implements OnInit {

  @Input("data") data:any         = {};
  @Input("template") template:any = null; 
  public url  = null;

  constructor(
    private sanitizer: DomSanitizer
  ) { 
    
  }

  open(){

    window.open("https://www.google.com/maps/place/"+this.data.params.endereco+","+this.data.params.numero+"+-+"+this.data.params.bairro+",+"+this.data.params.cidade+"+-+"+this.data.params.estado+",+"+this.data.params.cep,"_blank")

  }
  /**
   * 
   * Seta a URL
   * 
   */
  setUrl(){   
 
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed/v1/place?key=AIzaSyDwKvon5PVhcFzn1a60p1dzEGI-pwhNsng&q="+this.data.params.endereco+","+this.data.params.numero+"+-+"+this.data.params.bairro+",+"+this.data.params.cidade+"+-+"+this.data.params.estado+",+"+this.data.params.cep);

  } 
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.setUrl();
  }

}
