import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-certificado-cadastur',
  templateUrl: './certificado-cadastur.component.html',
  styleUrls: ['./certificado-cadastur.component.scss']
})
export class CertificadoCadasturComponent implements OnInit {

  @Input("data") data:any         = {};
  @Input("template") template:any = null;
  public image = "";
  
  constructor() { }

  /**
   * 
   * Set Image
   * 
   */
  setImage(){

    this.image = "/images/modulo/certificado-cadastur/"+this.data.params.imagem;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.setImage();
  }

}
