import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';
import { Subscription, Subject } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd, RouterEvent } from '@angular/router';
import { takeUntil, filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ProdutoFormAgendamento } from 'src/app/components/produto/produto-form-agendamento/produto-form-agendamento.component';

@Component({
  selector: 'servico-page',
  templateUrl: './servico-page.component.html',
  styleUrls: ['./servico-page.component.scss']
})
export class ServicoPage implements OnInit,OnDestroy, AfterViewInit {

  public apelido = null;
  public produto = null;
  public categoria = null;
  public fotos   = [];
  public loader  = false;
  public messageLoader = "Carragendo Serviço...";
  public response      = {
    data: "",
    status: null
  }
  public macro_regiao      = [];
  public regiao:any            = {};
  public configuracao_loja = null;
  public ultimoServicoInserido   = null;
  public produtos_relacionados   = [];
  public mySubscription:Subscription;
  public destroyed               = new Subject<any>();
  public destroy                 = false;
  public itensBreadcrumbs        = [];
  public dias                    = null;
  public contentDescricao        = 'duracao';
  public incluso                 = [];
  public naoIncluso              = [];
  public avaliacaoMedia          = null;
  public avaliacaoMediaData      = null;
  public avaliacaoMediaItens     = [];
  public addCart                 = false;
  public show                    = false;
  public apelido_categoria       = ""; 
  public infos                   = [];

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private app: AppService,
    private dialog: MatDialog
  ){

    this.apelido_categoria = this.route.params["_value"].apelido_categoria;
    this.apelido           = typeof(this.route.params["_value"].apelido) != "undefined" ? this.route.params["_value"].apelido : null;
   
  }
  
  /**
   * 
   * Retorna os dados do produto baseado no apelido
   * 
   * 
   */
  getData(){

    try{

      this.loader           = true;
      this.response.status  = null; 
      this.itensBreadcrumbs = [];

      this.api.produto().produto(this.apelido).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          if(response.data.produto == null){
            this.router.navigateByUrl("/");
          }

          this.addCart               = false;
          this.produto               = response.data.produto;
          this.fotos                 = response.data.fotos;
          this.categoria             = response.data.categoria; 
          this.macro_regiao          = response.data.macro_regiao;
          this.dias                  = this.formatDias(response.data.dias);
          this.configuracao_loja     = response.data.configuracao_loja;
          this.ultimoServicoInserido = response.data.servico; 
          this.produtos_relacionados = response.data.relacionados;
          this.response.status       = 1; 
          this.separaCaracterisitcas(response.data.caracteristica);
          this.setContentDescricao();

          this.setItensBreadcrumbs();
          this.app.setMetaDados(this.produto.meta_title,this.produto.meta_description);
                       
        }else{
          this.response = response;
        }

      },(response) => {

        this.loader = false;
        let error   = this.app.formatError(response);

        this.response = {
          data: error.message,
          status: 500
        }

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Separa as características
   * 
   */
  separaCaracterisitcas(caracteristicas){
    
    let incluso    = [];
    let naoIncluso = [];

    for (let index = 0;index < caracteristicas.length;index++) {
      
      if(caracteristicas[index].tipo == 1){
        
        incluso.push({
          descricao: caracteristicas[index].descricao
        });

      }else{
        
        naoIncluso.push({
          descricao: caracteristicas[index].descricao
        }); 
      
      }
      
    }

    this.incluso    = incluso; 
    this.naoIncluso = naoIncluso;

  }
  /**
   * 
   * Set content descrição
   * 
   */
  setContentDescricao(){

    if(this.produto.duracao !== null && this.produto.duracao !== ""){
      
      this.infos.push({
        titulo: "Duração",
        apelido: "duracao"
      });
    
    }  
    if(this.incluso.length > 0){
      
      this.infos.push({
        titulo: "Incluso",
        apelido: "incluso"
      });
    
    } 
    if(this.naoIncluso.length > 0){
      
      this.infos.push({
        titulo: "Não Incluso",
        apelido: "nao-incluso"
      });
    
    } 
    if(this.produto.observacoes !== null && this.produto.observacoes !== ""){
      
      this.infos.push({
        titulo: "Observações",
        apelido: "observacoes"
      });
    
    }
    if(this.produto.dicas !== null && this.produto.dicas !== ""){
      
      this.infos.push({
        titulo: "Dicas",
        apelido: "dicas"
      });
    
    }  
    if(this.produto.informacoes !== null && this.produto.informacoes !== ""){
      
      this.infos.push({
        titulo: "Informações",
        apelido: "informacoes"   
      });
    
    }    
     
    if(this.infos.length > 0){ 
      this.contentDescricao = this.infos[0].apelido;
    }

  }
  /***
   * 
   * Seta os breadcrumbs
   * 
   */
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({ 
      text: "Serviços",
      routerLink: "/servicos"
    });
    this.itensBreadcrumbs.push({
      text: this.categoria.titulo,
      routerLink: "/servicos/"+this.apelido_categoria
    });
    this.itensBreadcrumbs.push({
      text: this.produto.nome
    });

  }
  /**
   * 
   * Formata os dias
   * 
   */
  formatDias(dias){

    let result = null;

    if(dias != null){

      result = "";

      for (let index = 0; index < dias.length; index++) {
        
        if(index > 0){
          result += ", ";
        }
        result += dias[index].descricao;
        
      }

    }
    
    return result;

  }
  /**
   * 
   * Open form agendamento
   * 
   */
  openFormAgendamento(){

    this.dialog.open(
      ProdutoFormAgendamento,{
        width: "700px",
        height: "auto",
        data: {
          servico: this.produto
        }
      });

  }
  /**
   * 
   * After loader view
   * 
   */
  ngAfterViewInit(): void {
    
    if(this.mySubscription){
      
      this.destroy         = true;
      this.addCart         = false;
      this.mySubscription.unsubscribe(); 
    
    }

  }
  /**
   * 
   * On Destroy
   * 
   */
  ngOnDestroy(): void {
    
    if(this.mySubscription){
      this.destroy         = true;
      this.addCart         = false;
      this.mySubscription.unsubscribe(); 
    }


  }
  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(): void {
    this.app.toTop();
    this.getData();
    this.addCart = false;

    this.mySubscription = this.router.events.pipe(
      filter((event:RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      
      if(!this.destroy){
        this.apelido           = this.route.params["_value"].apelido; 
        this.apelido_categoria = this.route.params["_value"].apelido_categoria;
        this.getData();
      }  

    });
  }

}
