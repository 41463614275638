import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'modulo-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit,AfterViewInit {

  @Input("data")  data         = null; 
  @Input("template")  template = null;
  @ViewChild("tituloMain") titulo:ElementRef;   
  @ViewChild("btn") btn:ElementRef;    
  public imagem                = null;   

  constructor() { }
  
  /***
   * 
   * 
   * 
   */
  setImagem(){

    this.imagem = "/images/modulo/banner/"+this.data.params.imagem;

  }
  /**
   * 
   * @param elemento 
   */
  typeWrite(){
    
    try{
      
      let texto = this.data.titulo.split('');   
      this.titulo.nativeElement.innerHTML = ' ';
      

      for (let index = 0; index < texto.length; index++) {
        
        setTimeout(() => {
         this.titulo.nativeElement.innerHTML += texto[index];
        },75 * index); 

      }

      setTimeout(() => {
        
      },1000);
      

    }catch(e){
      
    }  

  }
  /**
   * 
   * After view
   * 
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.typeWrite();
    },200);
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.setImagem();
    
  }

}
