import { Component, OnInit, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'modulo-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {

  @Input("data") data:any       = null;
  @Input("servico") servico:any = null;
  @Input("template") template   = null;
  public prefixo                = "web";
  public numero                 = "";
  public text                   = "Clique aqui e tire suas dúvidas via WhatsApp"; 

  constructor(
    private deviceService: DeviceDetectorService
  ){ 
    
  }
  open(){

    if(this.servico != null){
      this.text = "Quero mais informações sobre o serviço: "+this.servico.nome;
    }

    if(this.text !== "" && this.text !== null){
      window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+this.numero+"&text="+this.text,"__blank");
    }else{
      window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+this.numero,"__blank");
    }  
  }
  /**
   * 
   * Seta o número
   * 
   */
  setNumero(){
    
    let telefone = this.data.params.telefone.replace("(","").replace(")","").replace("-","").replace(" ","");

    this.numero = "55"+telefone;


  }
  /**
   * 
   * Seta a mensage
   * 
   */
  setMessage(){

    if(typeof(this.data.params.texto) != "undefined"){
      
      this.text = this.data.params.texto;

    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setNumero();
    this.setMessage();
    this.prefixo = this.deviceService.isDesktop() ? "web" : "api";
  }

}
