import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'box-form-busca',
  templateUrl: './form-busca.component.html',
  styleUrls: ['./form-busca.component.scss']
})
export class FormBuscaComponent implements OnInit {

  public categoria    = '1';
  public termo        = "";
  public _placeholder = "Escreva aqui para encontrar o evento ideal"

  constructor(
    private app: AppService,
    private api: ApiService,
    private router: Router
  ) { }

  /**
   * 
   * Inicializa as Funções
   * 
   */
  send(){

    switch(this.categoria){
      case "1":
        this.router.navigate(["/eventos"],{queryParams:{termo: this.termo}});
      break;
      case "2":
        this.router.navigate(["/servicos/passeios"],{queryParams:{termo: this.termo}});
      break;
      case "3":
        this.router.navigate(["/servicos/ingressos"],{queryParams:{termo: this.termo}});
      break;
      case "4":
        this.router.navigate(["/servicos/transfers"],{queryParams:{termo: this.termo}});
      break;
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{}

}
