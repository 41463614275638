import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'produto-form-search',
  templateUrl: './produto-form-search.component.html',
  styleUrls: ['./produto-form-search.component.scss']
})
export class ProdutoFormSearchComponent implements OnInit {

  public dataItem:any  = {};
  @Input("idCidade") idCidade       = null; 
  @Input("produtos") produtos       = []; 
  @Input("apelidoDica") apelidoDica = null; 
  @Input("apelidoCategoria") apelidoCategoria = null; 
  @Input("termo") termo             = null; 
  @Input("categorias") categorias   = [];  
  @Input("classificacao") classificacao = [];
  @Input("periodos") periodos      = [];
  @Input("valores") valores        = [];
  @Input("cidades") cidades        = []; 
  @Input("template")     template  = "one"; 
  @Output("updateData") updateData = new EventEmitter(); 
  @Output("change") _change        = new EventEmitter();
  public myControl                 = new FormControl();
  public options:string[]          = [];
  public filteredOptions:Observable<string[]>;
  public loader = false;
  @Input("clearForm") clearForm  = new EventEmitter();
  public showContent = false;  
  
  constructor(
    private api: ApiService
  ){ 
    this.initDataItem();
  }

  /**
   * 
   * Pesquisar
   * 
   * 
   */
  pesquisar(){

    try{

      this.loader = true;

      this.api.produto().search(this.dataItem).subscribe((response) => {

        this.loader = false;

        if(response.status == 1){
          this.updateData.emit(response.data);
        }  

      },(response) => {

        this.loader = false;
        
      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      tipo_periodo_id: "",
      produto_cidade_id: this.idCidade,
      produto_categoria_id: "",
      produto_classificacao_id: "",
      termo: this.termo,
      preco: "",
      apelido_dica: this.apelidoDica,
      apelido_categoria: this.apelidoCategoria
    }

  }
  /**
   * 
   * Filter
   * 
   * @param value 
   * 
   */
  private _filter(value: string): string[] {
    
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  
  }
  /***
   * 
   * Set Options
   * 
   */
  setOptions(id=null){

    this.options = [];

    for(let index = 0; index < this.produtos.length; index++) {
      
      if(id == null){
        this.options.push(this.produtos[index].nome); 
      }else if(id == this.produtos[index].produto_categoria_id){
        this.options.push(this.produtos[index].nome); 
      }  
      
    }
      

  }
  _changeInput(){

    this._change.emit(this.dataItem); 

  }
  /**
   * 
   * Categoria
   * 
   */
  _changeCategoria(){

    this.dataItem.nome = "";
    this._change.emit(this.dataItem); 

  }
  /**
   * 
   * Categoria
   * 
   */
  _changeRegiao(){

    this.dataItem.nome = "";
    this._change.emit(this.dataItem); 

  }
  /**
   * 
   * Filtered
   * 
   */
  _filtered(){
    
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
    );
    
  }
  /**
   * 
   * On Events
   * 
   */
  onEvents(){ 

    this.clearForm.subscribe(response => {

      this.initDataItem();

    }); 

  }
  /***
   * 
   * Show content
   * 
   */
  _showContent(){ 

    this.showContent = this.showContent == true ? false : true;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    
    this.setOptions();

    this.myControl.valueChanges.subscribe(data => {
      this._changeInput();
    });
    this._filtered();
    this.onEvents();
         
  }
  /**
   * 
   * On Changes
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes:any): void {
   
    if(changes.produtos){
      this.produtos = changes.produtos.currentValue;
      this._filtered();
    }
    if(changes.termo){
      this.dataItem.termo = changes.termo.currentValue;
    }
    if(changes.apelidoDica){
      this.dataItem.apelido_dica = changes.apelidoDica.currentValue;
    }
    if(changes.apelidoCategoria){
      this.dataItem.apelido_categoria = changes.apelidoCategoria.currentValue;
    }
    if(changes.idCidade){
      this.dataItem.produto_cidade_id = isNaN(parseInt(changes.idCidade.currentValue)) ? "" : parseInt(changes.idCidade.currentValue);
    }

  }

}
